import React from 'react';

function Loading(props) {
    let label = props.label || 'Loading...';

    return (
        <div className="fixed flex inset-0 items-center justify-center">
            <div>
                <p className="text-center text-6xl">
                    {Math.random() < .5 ?
                        <span role="img" aria-label="Golfer">🏌️‍♀️</span>
                        :
                        <span role="img" aria-label="Golfer">🏌️‍♂️</span>
                    }
                </p>
                <p className="text-center pulse">{label}</p>
            </div>
        </div>
    )
}

export default Loading;
