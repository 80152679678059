import React from 'react';

import Hole from './Hole';

var moment = require('moment-timezone');

const GENDER_LABELS = {
    'All': 'All',
    'Ladies': 'Ladies',
    'Mens': 'Men'
}

class Comp extends React.Component {
    onClick = () => {
        if (!this.props.onClick) return;

        this.props.onClick(this.props.index);
    }

    addHole = (e) => {
        if (!this.props.update) return;

        var data = this.props.data;
        var number = e.target.value;

        data.holes[number-1].isEnabled = true;
        this.props.update(this.props.index, data);
    }

    removeHole = (number) => {
        if (!this.props.update) return;

        var data = this.props.data;

        data.holes[number-1].isEnabled = false;
        this.props.update(this.props.index, data);
    }

    toggleSpecial = (number) => {
        if (!this.props.update) return;

        var data = this.props.data;

        data.holes[number-1].settings.isSpecial = !data.holes[number-1].settings.isSpecial;
        this.props.update(this.props.index, data);
    }

    changeDate = (e) => {
        if (!this.props.update) return;

        var data = this.props.data;
        var formattedDate = moment.tz(e.target.value, 'Australia/Darwin').format();
        var dateString = moment(e.target.value).format('DD MMM');
        var dateInt = moment.tz(e.target.value, 'Australia/Darwin').valueOf();

        data['date'] = formattedDate;
        data['dateString'] = dateString;
        data['dateInt'] = dateInt;

        this.props.update(this.props.index, data);
    }

    change = (e) => {
        if (!this.props.update) return;

        var data = this.props.data;
        var key = e.target.name;
        var value = e.target.value;

        data[key] = value;
        this.props.update(this.props.index, data);
    }

    render() {
        var activeColour;
        activeColour = this.props.isUpcoming ? 'blue' : 'white';
        if (this.props.data.isCustom) activeColour = 'green';

        var inputDateString = moment(this.props.data.date).format('YYYY-MM-DD');
        // var date = new Date(this.props.data.date);
        // var inputDateString = date.toLocaleDateString('zh-Hans-CN', {year: 'numeric', month: '2-digit', day:'2-digit'});
        // inputDateString = inputDateString.replace(/\//g, '-');

        return (
            <React.Fragment>
                <tr
                    className={
                        (this.props.data.hasClosestComp ?
                            'border border-b-0 bg-'+ activeColour +'-100 border-'+ activeColour +'-300'
                            :
                            'border-0 border-t border-gray-300 hover:bg-gray-100')
                    }>
                    <td onClick={this.onClick} className={
                        ('px-4 sm:px-8 ') + 
                        (this.props.onClick ? 'cursor-pointer ' : '')
                        }>
                        {this.props.isUpcoming &&
                            <input
                                type="checkbox"
                                readOnly
                                checked={this.props.data.hasClosestComp || false}
                                className={this.props.onClick ? 'cursor-pointer ' : ''}
                            />
                        }
                    </td>
                    <td onClick={this.props.isUpcoming ? null : this.onClick} className={
                        'py-4 hidden sm:table-cell ' +
                        (this.props.isUpcoming ? '' : 'cursor-pointer')
                        }>
                        {moment(this.props.data.dateInt).format('dddd')} {this.props.data.dateString}
                        <br />
                        <span className="text-sm text-gray-600 whitespace-no-wrap">
                            {this.props.data.time}
                            <span className="text-gray-300">  •  </span>
                            {this.props.data.gender}
                        </span>
                    </td>
                    <td onClick={this.props.isUpcoming ? null : this.onClick} className={
                        'py-4 px-0 sm:px-8 ' + 
                        (this.props.data.hasClosestComp ? 'border-r border-'+ activeColour +'-300 sm:border-0 ' : '') +
                        (this.props.isUpcoming ? '' : 'cursor-pointer')
                        }>
                        <small className="block sm:hidden">
                            <strong>{moment(this.props.data.dateInt).format('ddd')} {this.props.data.dateString}</strong>
                            <span className="text-gray-300">  •  </span>
                            <span className="text-sm text-gray-600 whitespace-no-wrap">
                                {this.props.data.time}
                                <span className="text-gray-300">  •  </span>
                                {this.props.data.gender}
                            </span>
                        </small>

                        {/* TITLE */}
                        <div className="relative">
                            {/* SPACER */}
                            <span className={'p-2 -m-2 whitespace-pre-wrap opacity-0'}>
                                {this.props.data.title}
                            </span>

                            {/* ACTUAL INPUT */}
                            <textarea
                                type="text"
                                name="title"
                                value={this.props.data.title}
                                onChange={this.change}
                                disabled={!this.props.isUpcoming}
                                className={
                                    'p-2 -m-2 rounded-l bg-transparent resize-none absolute inset-0 ' +
                                    (this.props.isUpcoming ? 'hover:bg-gray-400 hover:bg-opacity-25 transition-colors duration-200' : 'pointer-events-none')
                                }
                            />
                        </div>

                        {this.props.data.comments &&
                            <span className="text-sm text-gray-600">{this.props.data.comments}</span>
                        }
                    </td>
                </tr>
                {this.props.data.hasClosestComp &&
                    <tr className={'border border-t-0 bg-'+ activeColour +'-100 border-'+ activeColour +'-300'}>
                        <td>&nbsp;</td>
                        <td colSpan="2" className="pb-4">
                            <div className="">

                                {/* DATE */}
                                <div className={'mr-2 mb-2 px-4 py-2 inline-block relative rounded ' + (this.props.isUpcoming ? 'bg-white shadow hover:shadow-md transition-shadow duration-75 ease-linear' : 'bg-gray-200')}>
                                    <span className="text-gray-600">On</span>
                                    <div className="inline-block pl-2 text-center">
                                        <input
                                            type="date"
                                            name="date"
                                            className={'underline appearance-none focus:outline-none bg-transparent w-32 leading-5 ' + (this.props.isUpcoming ? 'cursor-pointer' : '')}
                                            disabled={!this.props.isUpcoming}
                                            onKeyDown={e => { e.preventDefault() }}
                                            value={inputDateString}
                                            onChange={this.changeDate}
                                        />
                                    </div>
                                </div>

                                {/* START/END TIMES */}
                                <div className={'mr-2 mb-2 px-4 py-2 inline-block relative rounded ' + (this.props.isUpcoming ? 'bg-white shadow hover:shadow-md transition-shadow duration-75 ease-linear' : 'bg-gray-200')}>
                                    <span className="text-gray-600">Open</span>
                                    <div className="inline-block px-2 text-center">
                                        <Time
                                            name="startTime"
                                            disabled={!this.props.isUpcoming}
                                            value={this.props.data.startTime}
                                            disableAfter={this.props.data.endTime}
                                            onChange={this.change}
                                        />
                                    </div>
                                    <span className="text-gray-600">to</span>
                                    <div className="inline-block pl-2 text-center">
                                        <Time
                                            name="endTime"
                                            disabled={!this.props.isUpcoming}
                                            value={this.props.data.endTime}
                                            disableBefore={this.props.data.startTime}
                                            onChange={this.change}
                                        />
                                    </div>
                                </div>

                                {/* GENDER */}
                                <div className={'mr-2 mb-2 px-4 py-2 inline-block relative rounded ' + (this.props.isUpcoming ? 'bg-white shadow hover:shadow-md transition-shadow duration-75 ease-linear' : 'bg-gray-200')}>
                                    <span className="text-gray-600">For </span>
                                    <span className="underline">{GENDER_LABELS[this.props.data.gender]}</span>
                                    <select
                                        name="gender"
                                        className={'absolute inset-0 opacity-0 appearance-none focus:outline-none bg-transparent ' + (!this.props.isUpcoming ? '' : 'cursor-pointer')}
                                        onChange={this.change}
                                        value={this.props.data.gender}
                                        disabled={!this.props.isUpcoming}>

                                        <option disabled>Gender</option>
                                        <option>All</option>
                                        <option>Ladies</option>
                                        <option>Mens</option>
                                    </select>
                                </div>

                                {/* HOLES */}
                                {this.props.data.holes.map(function(hole, i) {
                                    return (
                                        hole.isEnabled &&
                                            <Hole
                                                {...hole}
                                                remove={this.removeHole}
                                                toggleSpecial={this.toggleSpecial}
                                                isUpcoming={this.props.isUpcoming}
                                                key={i}
                                            />
                                    )
                                }, this)}

                                {/* ADD HOLE BUTTON */}
                                {this.props.isUpcoming &&
                                    <div className="inline-block relative w-32 mb-2">
                                        <select onChange={this.addHole} value="" className="block appearance-none w-full bg-white shadow hover:shadow-md transition-shadow duration-75 ease-linear cursor-pointer px-4 py-2 pr-8 rounded focus:outline-none">
                                            <option value="" disabled>Add a hole</option>
                                            {this.props.data.holes.map(function(hole, i) {
                                                return (
                                                    !hole.isEnabled &&
                                                        <option key={i}>{hole.number}</option>
                                                )
                                            }, this)}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>
                }
            </React.Fragment>
        )
    }
}

function Time(props) {
    const hours = ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const mins = ['00', '15', '30', '45'];

    return (
        <select
            className={'underline appearance-none focus:outline-none bg-transparent ' + (props.disabled ? '' : 'cursor-pointer')}
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            disabled={props.disabled}>

            <option disabled>Start</option>
            {hours.map((hour, i) => {
                return mins.map((minute, j) => {
                    const time = hour + ':' + minute;
                    const timeInt = parseInt( time.replace(':', '') );
                    const disableBeforeInt = props.disableBefore ? parseInt( props.disableBefore.replace(':', '') ) : 0;
                    const disableAfterInt = props.disableAfter ? parseInt( props.disableAfter.replace(':', '') ) : 9999;

                    return (
                        <option
                            disabled={timeInt <= disableBeforeInt || timeInt >= disableAfterInt}
                            key={String(i)+String(j)}>

                            {time}
                        </option>
                    )
                }, this)
            }, this)}
        </select>
    )
}

export default Comp;
