import React, { useState, useContext, createContext } from 'react';
import {
    Route,
    Redirect,
} from 'react-router-dom';

const authContext = createContext();

const fakeAuth = {
    isAuthenticated: false,
    signin({username, password}, onSuccess, onError) {
        // fetch('https://admin.gimmee.golf/.netlify/functions/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         username: username,
        //         password: password
        //     })
        // })
        // .then(response => {
        //     if (!response.ok) {
        //         throw Error(response.message);
        //     }
        //
        //     return response.json()
        // }).then((data) => {
        //     console.log(data);
        // }).catch((error) => {
        //     onError();
        // })

        if (username === 'admin' && password === 'gimmee123') {
            fakeAuth.isAuthenticated = true;
            setTimeout(onSuccess, 1000); // fake async
        } else {
            setTimeout(onError, 1000); // fake async
        }
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        cb();
        // setTimeout(cb, 1000);
    }
};

function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
            auth.user ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                    />
            )
        }
        />
    );
}

function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(global.isDebug || null);

    const signin = (credentials, onSuccess, onError) => {
        return fakeAuth.signin(
            credentials,
            () => {
                setUser("user");
                onSuccess();
            },
            onError
        );
    };

    const signout = cb => {
        return fakeAuth.signout(() => {
            setUser(null);
            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}

export {
    useAuth,
    useProvideAuth,
    PrivateRoute,
    ProvideAuth,
}
