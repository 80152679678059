import React, { useState, useEffect, useRef } from 'react';
import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
    useParams
} from 'react-router-dom';

function CompResultsOverlay(props) {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:compId`}>
                <CompResults comps={props.comps} />
            </Route>
        </Switch>
    )
}

function Distance({record}) {
    var styles = '';
    if (record.location) styles += ' underline cursor-pointer';

    if (!record.location) return (
        <p>
            {record.distance.amount}{record.distance.unit}
        </p>
    )

    const id = 'distance-details-' + record.user.firstName + record.user.lastName + record.holeIndex + Math.round(Math.random()*100);
    const location = record.location;
    const coords = location.coords;

    const popoverAttribute = { 'popover': '' }; // need this hacky thing to pass an empty attribute in react
    return (<>
        <button className={styles} popovertarget={id}>
            {record.distance.amount}{record.distance.unit}
        </button>

        <div {...popoverAttribute} id={id} className="p-8 rounded-3xl bg-white text-sm shadow-lg max-w-full relative">
                <button
                    type="button"
                    popovertarget={id}
                    popovertargetaction="hide"
                    className="absolute top-0 right-0 w-10 text-2xl text-gray-500 hover:text-black focus:outline-none">
                        &times;
                </button>

            <ul className="list-none">
                <li>
                    <strong className="w-32 inline-block">Added by:</strong> {record.addedBy.firstName} {record.addedBy.lastName}
                </li>
                <li>
                    <strong className="w-32 inline-block">Timestamp:</strong> {location.timestamp}
                </li>

                {Object.keys(coords).map((key, i) => {
                    return (
                        <li key={i}>
                            <strong className="w-32 inline-block">{key}</strong> {coords[key]}
                        </li>
                    )
                })}

                <li>
                    <a href={'https://www.google.com/maps/search/?api=1&query='+ coords.latitude +','+ coords.longitude} target="_blank" rel="noopener noreferrer" className="p-4 rounded-xl block mt-8 text-white font-bold text-center bg-green-500 hover:bg-green-600">
                        View on Google Maps
                    </a>
                </li>
            </ul>
        </div>
    </>)
}

function CompResults(props) {
    let { compId } = useParams();
    let history = useHistory();

    // const [comp] = useState( history.location.state?.comp );
    const [comp, updateComp] = useState( null );
    const [isAdding, setIsAdding] = useState(false);

    // on mount
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        // unmount cleanup
        return function cleanup() {
            document.body.classList.remove('overflow-hidden');
        }
    }, []);


    // set comp when available
    useEffect(() => {
        updateComp(props.comps?.filter(comp => comp.id === compId)?.[0]);
    }, [props.comps, compId])


    function closeOverlay() {
        if (comp) history.goBack();
        else history.replace('/results');
    }

    let holes = comp?.holes;
    let records = comp?.records;
    if (records) {
        records.sort((record1, record2) => record2.distance.amount - record1.distance.amount);
        records.sort((record1, record2) => record1.holeIndex - record2.holeIndex);
    }

    return (
        <div className="fixed inset-0 z-10 px-4 overflow-auto pb-12">
            <div className="fixed inset-0 bg-opacity-75 bg-black" onClick={closeOverlay} />
            <div className="relative bg-white rounded container mx-auto mt-12 p-4 w-full max-w-4xl">
                <button
                    type="button"
                    onClick={closeOverlay}
                    className="absolute top-0 right-0 -mr-0 -mt-16 text-5xl text-gray-500 hover:text-white focus:outline-none"
                >
                    &times;
                </button>

                {!comp &&
                    <p>Loading...</p>
                }

                {!!comp &&
                    <div>
                        <h3 className="font-medium text-gray-600">{comp.dateString}</h3>
                        <h2 className="text-2xl font-bold">{comp.title}</h2>

                        {!!holes?.length &&
                            <div className="mt-4 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
                                {holes.map((hole, i) => {
                                    if (!hole.isEnabled) return false;
                                    var holeHasRecords;

                                    return (
                                        <div className="p-2 bg-gray-200 rounded" key={i}>
                                            <h4 className="mt-2 font-bold text-center">
                                                {hole?.settings?.isSpecial && '🏆 '}
                                                Hole {hole.number}
                                            </h4>

                                            {!!records?.length &&
                                                <div className="mt-2">
                                                    {records.map((record, j) => {
                                                        if (record.holeIndex !== i) return false;
                                                        holeHasRecords = true;

                                                        return (
                                                            <div className="flex text-sm border-t border-gray-400 py-2 px-1 items-center" key={j}>
                                                                <span
                                                                    className={
                                                                        'inline-block w-2 h-2 mr-1 rounded ' + (
                                                                        record.user?.gender?.toLowerCase() === 'female' ?
                                                                            'bg-red-600'
                                                                            :
                                                                            'bg-gray-500'
                                                                        )
                                                                    }
                                                                />
                                                                <p className="flex-1">
                                                                    {record.user.firstName} {record.user.lastName}
                                                                </p>
                                                                <Distance record={record} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {!holeHasRecords &&
                                                <p className="text-center text-gray-700 text-sm pb-2">No records</p>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                }
            </div>

            {(isAdding && !!comp) &&
                <AddResult
                    compId={comp.id}
                    holes={holes.filter(hole => hole.isEnabled)}
                    onAdd={() => { setIsAdding(false) }}
                />
            }

            <button
                className={
                    'relative text-white block mx-auto mt-4 py-2 px-6 rounded-full focus:outline-none transition duration-100 ' +
                    (isAdding ? ' text-opacity-75 hover:text-opacity-100' : ' font-bold bg-gray-400 bg-opacity-25 hover:bg-white hover:bg-opacity-25')
                }
                onClick={() => { setIsAdding(!isAdding) }}>

                {isAdding ? 'Cancel' : 'Add Result'}
            </button>
        </div>
    )
}

function AddResult(props) {
    let holes = props.holes;
    let genders = [
        {
            value: "Male",
            label: "Male",
        },
        {
            value: "Female",
            label: "Female",
        },
    ];

    const isUnmounted = useRef(false);
    const [canAdd, setCanAdd] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedHole, selectHole] = useState();
    const [selectedGender, selectGender] = useState();
    const [firstName, updateFirstName] = useState('');
    const [surname, updateSurname] = useState('');
    const [amount, updateAmount] = useState('');

    useEffect(() => {
        setCanAdd(
            selectedHole !== undefined &&
            selectedGender !== undefined &&
            !!firstName &&
            !!surname &&
            !!amount
        );
    }, [selectedHole, selectedGender, firstName, surname, amount])

    // unmount cleanup
    useEffect(() => {
        return () => {
            isUnmounted.current = true;
        };
    }, []);

    function add() {
        console.log('adding to...', props.compId);
        let hole = holes[selectedHole];
        let holeIndex = hole.number - 1;
        let gender = genders[selectedGender];

        let data = {
            record: {
                holeIndex: holeIndex,
                distance: {
                    amount: amount,
                    unit: "cm"
                },
                user: {
                    firstName: firstName,
                    lastName: surname,
                    gender: gender.value
                }
            }
        }

        setIsSaving(true);
        setCanAdd(false);

        console.log(data);

        fetch('https://golf-data.herokuapp.com/closest/'+ props.compId +'/record', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then((data) => {
            if (isUnmounted.current) return;
            if (data.error) {
                console.log('Adding error', data.error.message);
                window.alert('Error adding record: ' + data.error.message);
                return;
            }

            props.onAdd();
        });
    }

    return (
        <div className="relative bg-white rounded container mx-auto mt-4 px-2 py-4 w-full max-w-4xl flex flex-wrap overflow-hidden pb-12">
            <RadioButtons
                name="hole"
                label="Hole"
                activeIndex={selectedHole}
                onChange={selectHole}
                buttons={holes}
            />

            <RadioButtons
                name="gender"
                label="Gender"
                activeIndex={selectedGender}
                onChange={selectGender}
                buttons={genders}
            />

        <div className="mx-2 mb-4 w-32">
                <label className="block" htmlFor="name">First Name</label>
                <input
                    className="w-full py-1 px-2 text-sm bg-gray-200 rounded-lg border border-gray-200 outline-none focus:bg-gray-300 focus:border-gray-300"
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => { updateFirstName(e.target.value) }}
                />
            </div>

            <div className="mx-2 mb-4 flex-grow">
                <label className="block" htmlFor="name">Surname</label>
                <input
                    className="w-full py-1 px-2 text-sm bg-gray-200 rounded-lg border border-gray-200 outline-none focus:bg-gray-300 focus:border-gray-300"
                    type="text"
                    id="surname"
                    name="surname"
                    value={surname}
                    onChange={(e) => { updateSurname(e.target.value) }}
                />
            </div>

            <div className="mx-2 mb-4 w-32">
                <label className="block" htmlFor="amount">Distance (cm)</label>
                <input
                    className="w-full py-1 px-2 text-sm bg-gray-200 rounded-lg border border-gray-200 outline-none focus:bg-gray-300 focus:border-gray-300"
                    type="number"
                    id="amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => { updateAmount(e.target.value) }}
                />
            </div>

            <button onClick={add} disabled={isSaving || !canAdd} className={
                    'absolute bottom-0 left-0 w-full p-2 transition-all duration-200 focus:outline-none ' +
                    (canAdd ?
                        ' text-white font-bold bg-green-500 hover:bg-green-600'
                        :
                        ' cursor-default bg-gray-200 text-gray-600'
                    )
                }>

                {(!isSaving && canAdd) && 'Add Result'}
                {(!isSaving && !canAdd) && 'Add Result'}

                {isSaving &&
                    <span className="pulse">
                        <span role="img" aria-label="Brain">🧠</span> Adding...
                    </span>
                }

            </button>
        </div>
    )
}

function RadioButtons(props) {
    return (
        <div className="mx-2 mb-4 ">
            <p>{props.label}</p>

            <div className="bg-gray-200 rounded-lg border border-gray-200 text-sm whitespace-no-wrap">
                {props.buttons.map((radio, i) => {
                    return (
                        <Radio
                            id={props.name + i}
                            name={props.name}
                            value={radio.value || radio.number}
                            label={radio.label || (props.label + ' ' + radio.number)}
                            active={i === props.activeIndex}
                            isFirst={i === 0}
                            isLast={i === props.buttons.length-1}
                            onChange={props.onChange}
                            key={i}
                            index={i}
                        />
                    )
                })}
            </div>
        </div>
    )
}

function Radio(props) {
    const activeContainerStyles = ' bg-white shadow rounded-lg z-10 relative';
    const activeTextStyles = ' font-bold text-black';

    const firstContainerStyles = ' ';
    const lastContainerStyles = ' ';

    const inactiveContainerStyles = ' ';
    const inactiveTextStyles = ' text-gray-600';

    return (
        <div className={
                'transition-all duration-200 inline-block bg-transparent py-1' +
                (props.active ? activeContainerStyles : inactiveContainerStyles) +
                (props.isFirst ? firstContainerStyles : ' ') +
                (props.isLast ? lastContainerStyles : ' ')}>

            <input className="appearance-none" type="radio" id={props.id} name={props.name} value={props.value} checked={props.active} onChange={() => {props.onChange(props.index)}} />
            <label className={'transition-all duration-200 py-2 px-2' + (props.active ? activeTextStyles : inactiveTextStyles)} htmlFor={props.id}>{props.label}</label>

        </div>
    )
}

export default CompResultsOverlay;
