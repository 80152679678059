import React from 'react';

class Hole extends React.Component {
    toggleSpecial = () => {
        this.props.toggleSpecial(this.props.number);
    }

    remove = () => {
        this.props.remove(this.props.number);
    }

    render() {
        const isSpecial = this.props.settings.isSpecial;

        return (
            <div className={
                    'inline-block relative w-24 mb-2 mr-2 px-4 py-2 rounded '
                    + (this.props.isUpcoming ?
                        'bg-white pr-6 shadow hover:shadow-md transition-shadow duration-75 ease-linear'
                        :
                        'bg-gray-200'
                    )
                }>
                <button onClick={this.toggleSpecial} className={'mr-2 transition-opacity duration-100 focus:outline-none' + (isSpecial ? '' : ' opacity-25 hover:opacity-50 grayscale')}>
                    <span role="img" aria-label="Special">🏆</span>
                </button>
                <span>{this.props.number}</span>
                {this.props.isUpcoming &&
                    <button onClick={this.remove} className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 font-bold text-xl focus:outline-none">&times;</button>
                }
            </div>
        )
    }
}

export default Hole;
