import React from 'react';

import Comp from './Comp';
import Loading from './Loading';

const DEFAULT_START_TIME = '07:00';
const DEFAULT_END_TIME = '18:00';
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class Upcoming extends React.Component {
    state = {
        comps: null,
        defaultHoles: [5, 8, 11, 16],
        updatedComps: [],
        isSaving: false,
        status: '',
    };

    getBlankComp = () => {
        var now = new Date();
        var tomorrow = new Date(now.getTime() + 1000*60*60*24);

        var defaultID = now.getTime();
        defaultID = defaultID.toString();

        var defaultDate = tomorrow.toISOString();
        var defaultDateString = tomorrow.getDate() + ' ' + MONTHS[tomorrow.getMonth()];
        var defaultDateInt = tomorrow.valueOf();
        var defaultGender = 'All';
        var defaultTimeString = 'Custom';
        var defaultTitle = 'Untitled comp';

        var blankComp = {
            isCustom: true,
            comments: false,
            date: defaultDate,
            dateString: defaultDateString,
            dateInt: defaultDateInt,
            gender: defaultGender,
            id: defaultID,
            time: defaultTimeString,
            title: defaultTitle
        }

        console.log(blankComp);

        return blankComp;
    }

    addBlankComp = () => {
        this.toggleComp(-1);
    }

    toggleComp = (i) => {
        var isNewComp = i === -1;

        var comp;
        if (isNewComp) comp = this.getBlankComp();
        else comp = this.state.comps[i];

        comp.hasClosestComp = !comp.hasClosestComp;

        // if enabling comp for the first time, initialise the holes
        if (comp.hasClosestComp && (!comp.holes || !comp.holes.length)) {
            comp.holes = [];
            for (var j = 0; j < 18; j++) {
                comp.holes.push({
                    isEnabled: this.state.defaultHoles.includes(j+1),
                    number: j+1,
                    settings: {}
                })
            }
        }

        // initialise properties if they don't exist yet
        if (!comp.records) comp.records = [];
        if (!comp.startTime) comp.startTime = DEFAULT_START_TIME;
        if (!comp.endTime) comp.endTime = DEFAULT_END_TIME;

        this.updateComp(i, comp);

        // this.setState({comps: comps});
    }

    updateComp = (i, comp) => {
        var isNewComp = i === -1;

        var comps = this.state.comps;
        var updatedComps = this.state.updatedComps;

        if (isNewComp) {
            comps.unshift(comp);
            updatedComps.unshift(true);
        } else {
            comps[i] = comp;
            updatedComps[i] = true;
        }

        this.setState({
            comps: comps,
            updatedComps: updatedComps
        });
    }

    findUpdatedComps = () => {
        var updatedComps = [];
        for (var i = 0; i < this.state.updatedComps.length; i++) {
            if (this.state.updatedComps[i]) {
                updatedComps.push(this.state.comps[i]);
            }
        }

        return updatedComps;
    }

    save = () => {
        if (this.state.isSaving) return;
        this.setState({
            isSaving: true,
            status: 'SAVING'
        });

        var comps = this.findUpdatedComps();

        console.log('Saving with', comps);
        if (global.isDebug) {
            console.log('Blocked save while debugging.');
            return;
        }

        this._saveRequest = fetch(global.baseURL + 'closest/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comps)
        })
        .then(response => response.json())
        .then(
            data => {
                this._saveRequest = null;
                this.setState({
                    status: 'SAVED'
                })

                window.setTimeout(() => { this.setState({
                    updatedComps: [],
                    isSaving: false,
                    status: ''
                })}, 2500)
            }
        );
    }

    componentDidMount() {
        console.log('getting data...');

        this._compsRequest = fetch(global.baseURL + 'bookings/all')
        .then(response => response.json())
        .then(
            data => {
                console.log('got data', data);
                this._compsRequest = null;
                this.setState({
                    comps: data.bookings
                });
            }
        );
    }

    componentWillUnmount() {
        if (this._compsRequest) this._compsRequest.cancel();
        if (this._saveRequest) this._saveRequest.cancel();
    }


    renderLoading() {
        return (
            <Loading label="Getting comps..." />
        )
    }
    render() {
        if (!this.state.comps) return this.renderLoading();

        return (
            <div className="container mx-auto px-4 py-8">

                <table className="table-auto text-left mx-auto">
                    <thead>
                        <tr className="border-0 border-b-2 border-black">
                            <th className="">&nbsp;</th>
                            <th className="py-2 hidden sm:table-cell">Date</th>
                            <th className="py-4 px-0 sm:px-8 sm:py-2 relative">
                                Event

                                <button onClick={this.addBlankComp} className="px-4 absolute right-0 text-blue-700 font-bold hover:text-blue-400 focus:outline-none">
                                    + Add comp
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.comps.map(function(comp, i) {
                            return (
                                <Comp
                                    data={comp}
                                    onClick={this.toggleComp}
                                    update={this.updateComp}
                                    isUpcoming
                                    index={i}
                                    key={i}
                                />
                            );
                        }, this)}
                    </tbody>
                </table>
                {this.state.updatedComps.length &&
                    <div className={'fixed flex inset-0 top-auto bg-gray-200 h-16 ' + (!this.state.isSaving ? ' slide-up' : '') + (this.state.status === 'SAVED' ? ' slide-down' : '')}>
                        {this.state.isSaving ?
                            <span className="m-auto text-center">
                                {this.state.status === 'SAVING' &&
                                    <span className="pulse">
                                        <span role="img" aria-label="Brain">🧠</span> Saving...
                                    </span>
                                }

                                {this.state.status === 'SAVED' &&
                                    <span>
                                        <span role="img" aria-label="Cool">😎</span> Done!
                                    </span>
                                }
                            </span>
                            :
                            <button onClick={this.save} className="w-full text-center text-white font-bold bg-green-500 hover:bg-green-600 transition-color duration-100 focus:outline-none">Save Changes</button>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default Upcoming;
