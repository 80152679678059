import io from "socket.io-client";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    useHistory,
} from 'react-router-dom';

import {
    useAuth,
    PrivateRoute,
    ProvideAuth,
} from './Auth';

import LoginPage from './LoginPage';
import Upcoming from './Upcoming';
import Results from './Results';
import CompResultsOverlay from './CompResultsOverlay';

// global.isDebug = true;
global.baseURL = 'https://golf-data.herokuapp.com/';
// global.baseURL = 'http://localhost:8080/';

function App() {
    const socket = useRef();
    const [results, setResults] = useState( null );

    // initialize socket
    useEffect(() => {
        socket.current = io('https://api.gimmee.golf');
        socket.current.on('connect', () => {
            console.log('connected');
        });

        socket.current.on('disconnect', () => {
            console.log('disconnected');
        });
    }, [])

    useEffect(() => {
        function receivedRecord({competition}) {
            if (!results) return console.log('Received a record but results haven\'t been initialised. Ignoring.');

            var bookings = results;
            var bookingIndex = bookings?.findIndex(booking => booking.id === competition.id);

            if (bookingIndex < 0 || bookingIndex === undefined)
                return console.log('Received records but couldn\'t find the comp to put it.', competition.id);

            bookings[bookingIndex].records = competition.records;
            setResults(bookings);
        }

        socket.current.off('record');
        socket.current.on('record', (data) => {
            console.log('got record', data);
            if (data) receivedRecord({ competition: data.competition })
        })
    }, [results]);

    return (
        <ProvideAuth>
        <Router>
            <div className="App">
                <Nav />

                <Switch>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <PrivateRoute path="/results">
                        <CompResultsOverlay
                            comps={results}
                        />

                        <Results
                            comps={results}
                            setComps={setResults}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/">
                        <Upcoming />
                    </PrivateRoute>
                </Switch>
            </div>
        </Router>
        </ProvideAuth>
    );
}

function Nav() {
    const auth = useAuth();

    return auth.user ? (
        <nav>
            <ul className="bg-gray-900 flex justify-left sm:justify-center relative">
                <li>
                    <NavLink exact to="/" className="py-4 px-4 mx-0 inline-block font-medium text-white hover:bg-gray-800">
                        <span role="img" aria-label="Lightning">⚡️</span> Live &amp; Upcoming
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/results" className="py-4 px-4 mx-0 inline-block font-medium text-white hover:bg-gray-800">
                        <span role="img" aria-label="Golf">⛳️</span> Results
                    </NavLink>
                </li>
                <li className="absolute right-0 text-white h-full flex justify-center px-4 sm:px-8 opacity-75 hover:opacity-100">
                    <AuthButton />
                </li>
            </ul>
        </nav>
    ) : null;
}

// AUTH BUTTON
function AuthButton() {
    let history = useHistory();
    let auth = useAuth();

    return auth.user ? (
        <button
            className="focus:outline-none"
            onClick={() => {
                auth.signout(() => history.push("/"));
            }}>
            Log out
        </button>
    ) : (
        <p>You are not logged in.</p>
    );
}

export default App;
