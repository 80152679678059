import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Comp from './Comp';
import Loading from './Loading';

function Results(props) {
    let history = useHistory();
    let comps = props.comps;

    useEffect(() => {
        var _compsRequest = fetch(global.baseURL + 'closest/results')
        .then(response => response.json())
        .then(
            data => {
                console.log('got data', data);
                _compsRequest = null;

                props.setComps(data.bookings);
            }
        );

        return function cleanup() {
            if (_compsRequest) _compsRequest.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function showCompResults(comp) {
        history.push('/results/' + comp.id);
    }

    if (!comps) return <Loading label="Getting results..." />;
    return (
        <div className="container mx-auto px-4 py-8">
            <table className="table-auto text-left mx-auto">
                <thead>
                    <tr className="border-0 border-b-2 border-black">
                        <th className="">&nbsp;</th>
                        <th className="py-2 hidden sm:table-cell">Ended</th>
                        <th className="py-4 px-0 sm:px-8 sm:py-2">Event</th>
                    </tr>
                </thead>
                <tbody>
                    {comps.map(function(comp, i) {
                        return (
                            <Comp
                                data={comp}
                                onClick={() => {showCompResults(comp)}}
                                index={i}
                                key={i}
                            />
                        );
                    }, this)}
                </tbody>
            </table>
        </div>
    )
}

export default Results;
