import React, { useState } from 'react';
import {
    useHistory,
    useLocation
} from 'react-router-dom';

import {
    useAuth
} from './Auth';

import logo from './images/logo.jpg'

function LoginPage() {
    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [isLoading, setIsLoading] = useState(false);
    let [isError, setIsError] = useState(false);
    let { from } = location.state || { from: { pathname: "/" } };

    let login = () => {
        setIsLoading(true);
        setIsError(false);

        auth.signin(
            // credentials
            {
                username: username,
                password: password
            },
            onSuccess,
            onError
        );
    };

    let onSuccess = () => {
        history.replace(from);
    }

    let onError = () => {
        setUsername('');
        setPassword('');
        setIsLoading(false);
        setIsError(true);
    }

    return (
        <form className="w-64 pb-16 flex flex-col mx-auto h-screen justify-center">
            <div className="relative mx-auto mb-4 w-16 h-16 gimmee-green rounded-2xl overflow-hidden">
                <img src={logo} className="w-16 h-16" alt="Gimmee Logo" />
            </div>

            <p className="mb-4 text-center">
                Log in to Gimmee Admin
            </p>

            {!!isError &&
                <div className="rounded-lg bg-red-200 overflow-hidden relative text-sm px-4 py-2 mb-4 text-red-800">
                    <span className="bg-red-600 absolute inset-y-0 left-0 w-1" />
                    <p>Username or password incorrect</p>
                </div>
            }

            <input
                className="mb-2 w-full p-2 bg-gray-200 rounded-lg border border-gray-200 outline-none focus:bg-gray-300 focus:border-gray-300"
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={({target}) => { setUsername(target.value) }}
            />
            <input
                className="mb-2 w-full p-2 bg-gray-200 rounded-lg border border-gray-200 outline-none focus:bg-gray-300 focus:border-gray-300"
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={({target}) => { setPassword(target.value) }}
            />
            <button
                className={
                    'mt-2 p-2 font-bold rounded-lg transition duration-200 focus:outline-none ' +
                    (isLoading ?
                        ' cursor-default bg-gray-200 text-gray-600'
                        :
                        ' bg-green-500 hover:bg-green-600 text-white'
                    )
                }
                disabled={isLoading}
                onClick={login}>

                {isLoading ?
                    <span className="pulse" role="img" aria-label="Brain">🧠</span>
                    :
                    <span>
                        <span className="w-6 inline-block"><ion-icon name="lock-closed" /></span>
                        Log in
                    </span>
                }
            </button>
        </form>
    );
}

export default LoginPage;
